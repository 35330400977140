import {Ukrainian} from "flatpickr/dist/l10n/uk";

addEventListener("turbo:load", function () {
    const reservationForm = document.querySelector("#new_reservation");
    if (reservationForm) {
        reservationForm.addEventListener("input", function () {
            calculateReservationSum(this, function (data) {
                if (data != null) {
                    // Disable button if status is an error
                    // Otherwise enable button
                    let reserveButton = document.querySelector("#reserveButton");
                    reserveButton.disabled = data.status === "error";

                    let sum = data.sum;
                    let tariff_title = data.tariff_title;
                    let people_count = parseInt(data.people_count);
                    let services = data.services;

                    if (sum != null && tariff_title != null && people_count != null && services != null) {
                        document.querySelector("#reservation_sum_info").innerHTML = `${parseFloat(sum)} ₴`;
                    } else {
                        document.querySelector("#reservation_sum_info").innerHTML = "0.0 ₴";
                        console.log(data.message);
                    }
                    if (tariff_title != null && people_count != null && services != null) {
                        document.querySelector("#reservation_tariff_info").innerHTML = tariff_title;
                        document.querySelector("#reservation_tariff_services_info").innerHTML = generateTariffServices(services);
                        if (people_count > 0) {
                            let people_count_block = document.querySelector("#reservation_people_count_info");
                            people_count_block.parentElement.hidden = false;
                            people_count_block.innerHTML = people_count;
                        } else {
                            document.querySelector("#reservation_people_count_info").parentElement.hidden = true;
                        }
                    }

                    if (data.overflow_price) {
                        document.querySelector("#morePersonBlock").style.display = "block";
                        document.querySelector("#morePersonSum").innerHTML = `${parseFloat(data.overflow_price)} ₴`;
                    }

                    if (data.people_count_min) {
                        document.querySelector("#peopleCountMin").innerHTML = data.people_count_min;
                        document.querySelector("#reservation_people_count").min = data.people_count_min;
                    }
                }
            });
        });
    }
});

function generateTariffServices(services) {
    let services_html = "";
    for (let i = 0; i < services.length; i++) {
        services_html += `<li class="flex items-center gap-x-4 md:pl-4 lg:pl-6 pl-8 font-medium text-xl md:text-sm lg:text-base text-xl">
                              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                <path d="M18 0L18.3044 8.32399C18.4906 13.4166 22.5224 17.5007 27.5472 17.6869L36 18L27.5472 18.3131C22.5224 18.4993 18.4906 22.5834 18.3044 27.676L18 36L17.6956 27.676C17.5094 22.5834 13.4776 18.4993 8.45284 18.3131L0 18L8.45284 17.6869C13.4776 17.5007 17.5094 13.4166 17.6956 8.32399L18 0Z" fill="#1C1C1C"/>
                              </svg>
                              <span>${services[i]}</span>
                          </li>`;
    }
    return services_html;
}

function calculateReservationSum(form, callback) {
    Rails.ajax(
        {
            url: "/reservations/calculate_sum",
            type: "GET",
            data: new URLSearchParams(new FormData(form)).toString(),
            dataType: "json",
            success: function (data) {
                callback(data);
            },
            error: function (data) {
                callback(data);
            }
        }
    )
}
